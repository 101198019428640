import { loading, replaceState } from "@/utils/common";
import { OtpResponse } from "@/entities/otpResponse";
import { get } from "@/utils/httpRequest";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { otpKey } from "@/store";

export default defineComponent({
    name: "InitView",
    props: {
        scaSessionId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            redirectUrl: <string | null>"",
            store: useStore(otpKey),
        };
    },
    created() {
        replaceState();
        this.redirectUrl = useRouter().currentRoute.value.query.merchantUrl as string;
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            loading();
            try {
                const response = <OtpResponse>await get(`api/verifyotp/init/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);
                this.store.commit("setOtp", response.data);

                //redirect to error (or restart) view
                if (!response.isSuccess) {
                    this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${response.data?.redirectUrl ?? this.redirectUrl}`);
                }

                //redirect back to merchant
                if (response.data && response.data.redirectUrl && response.returnToMerchant) {
                    window.location.replace(response.data.redirectUrl);
                }

                //redirect to next step
                if (response.view) {
                    this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);
                }
            } catch (e) {
                console.log("Error while loading page data.", e);
            } finally {
                loading();
            }
        },
    },
});
