import { LanguageCode, languageCodeStringToEnum } from "@/enums";
import { getTextResources } from "tests/entities";
import { CheckoutInfo, OtpResponse, TextResourceList } from "@/entities";
import { getTranslations, loading, replaceState, translation } from "@/utils/common";
import { defineComponent } from "vue";
import { get } from "@/utils/httpRequest";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { otpKey } from "@/store";

export default defineComponent({
    name: "ErrorView",
    props: {
        scaSessionId: {
            type: String,
            required: false,
        },
    },
    data: function () {
        return {
            isDeadEnd: false,
            isSmsDisabled: false,
            data: <CheckoutInfo | null>new CheckoutInfo(),
            textResourceList: new TextResourceList(),
            store: useStore(otpKey),
        };
    },
    async created() {
        replaceState();
        const merchantUrl = useRouter().currentRoute.value.query.merchantUrl as string;

        this.data = this.store.state.otp ?? this.data;
        if (!this.data?.redirectUrl?.length) {
            this.data.redirectUrl = merchantUrl;
        }

        loading();
        try {
            if (this.scaSessionId) {
                const response = <OtpResponse>await get(`api/verifyotp/init/${this.scaSessionId}`);
                this.isDeadEnd = response.isDeadEnd;
                this.isSmsDisabled = response.message == "SmsLimitReached";
                this.data = this.data ?? response.data;
            }

            if (!this.data?.redirectUrl?.length) {
                this.data.redirectUrl = merchantUrl;
            }
        } catch (e) {
            console.log("Error while loading page data.", e);
        } finally {
            loading();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            loading();
            try {
                this.textResourceList = (await this.getTextResources(this.data?.countryCode, languageCodeStringToEnum(this.data?.conversationLanguage), fetchFromTest, httpsAgent)) as TextResourceList;
            } catch {
                this.textResourceList = new TextResourceList();
                this.textResourceList.textResourceItems = [
                    { sysName: "OtpErrorTitle", value: "We are sorry! Something went wrong." },
                    { sysName: "OtpErrorSubTitle", value: "An error occurred while processing your request." },
                    { sysName: "OtpRestartButton", value: "Restart" },
                    { sysName: "OtpReturnToShopButton", value: "Return to shop" },
                ];
                return;
            } finally {
                loading();
            }
        },
        reStartVerification() {
            this.$router.replace(`/otp/verifyotpauthentication/init/${this.data?.scaSessionId ?? this.scaSessionId}?merchantUrl=${this.data?.redirectUrl}`);
        },
        redirectToMerchant() {
            if (this.data?.redirectUrl) {
                window.location.replace(this.data.redirectUrl);
            }
        },
        async getTextResources(countryCode: string | null | undefined, languageCode: LanguageCode, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["OtpDeadEndErrorTitle", "OtpDeadEndErrorSubTitle", "OtpErrorTitle", "OtpErrorSubTitle", "OtpRestartButton", "OtpReturnToShopButton"];

            return await getTranslations(sysnameList, countryCode, languageCode, fetchFromTest, httpsAgent);
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList?.textResourceItems, sysName, replace);
        },
    },
});
