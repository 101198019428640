import { createApp } from "vue";
import { defineCustomElements } from "@riverty/web-components/loader";
import { otpKey, otpStore } from "./store";
import App from "./App.vue";
import router from "./router";
import { vMaska } from "maska/vue";
import { LogoComponent } from "./components";

const app = createApp(App);
defineCustomElements(window);
app.component("Logo", LogoComponent);
app.use(otpStore, otpKey);
app.use(router);
app.directive("maska", vMaska);
app.mount("#app");
