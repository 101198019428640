import { LanguageCode } from "@/enums";
import { getTextResources } from "tests/entities";
import { CheckoutInfo, TextResourceList } from "@/entities";
import { getTranslations, loading, replaceState, translation } from "@/utils/common";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { otpKey } from "@/store";

export default defineComponent({
    name: "CompletedView",
    data: function () {
        return {
            data: new CheckoutInfo(),
            language: LanguageCode.EN,
            countryCode: "EN",
            textResourceList: new TextResourceList(),
            store: useStore(otpKey),
        };
    },
    created() {
        replaceState();
        this.data = this.store.state.otp;
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            loading();
            try {
                this.textResourceList = (await this.getTextResources(this.countryCode, this.language, fetchFromTest, httpsAgent)) as TextResourceList;
            } catch {
                return;
            } finally {
                loading();
            }
        },
        async getTextResources(countryCode: string, languageCode: LanguageCode, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["OtpAlreadyCompletedTitle", "OtpAlreadyCompletedSubTitle"];

            return await getTranslations(sysnameList, countryCode, languageCode, fetchFromTest, httpsAgent);
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList?.textResourceItems, sysName, replace);
        },
    },
});
