import { LanguageCode } from "@/enums";
import { getTextResources } from "tests/entities";
import { CheckoutInfo, OtpResponse, TextResourceList } from "@/entities";
import { getTranslations, loading, replaceState, translation } from "@/utils/common";
import { defineComponent } from "vue";
import { get } from "@/utils/httpRequest";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { otpKey } from "@/store";

export default defineComponent({
    name: "ExpiredView",
    props: {
        scaSessionId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            language: LanguageCode.EN,
            countryCode: "EN",
            data: <CheckoutInfo | null>new CheckoutInfo(),
            textResourceList: new TextResourceList(),
            store: useStore(otpKey),
            showBackButton: true,
        };
    },
    async created() {
        replaceState();
        const merchantUrl = useRouter().currentRoute.value.query.merchantUrl as string;

        this.data = this.store.state.otp;
        if (!this.data?.redirectUrl?.length) {
            this.data.redirectUrl = merchantUrl;
        }

        loading();
        try {
            const response = <OtpResponse>await get(`api/verifyotp/init/${this.scaSessionId}?merchantUrl=${this.data.redirectUrl}`);
            this.checkShowButton(response);

            //redirect to error view
            if (!response.isSuccess && response.view != "Expired") {
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.data.redirectUrl}`);
            }

            this.data = response.data ?? new CheckoutInfo();

            if (!this.data?.redirectUrl?.length) {
                this.data.redirectUrl = merchantUrl;
            }
        } catch (e) {
            console.log("Error while loading page data.", e);
        } finally {
            loading();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null) {
            loading();
            try {
                this.textResourceList = (await this.getTextResources(this.countryCode, this.language, fetchFromTest, httpsAgent)) as TextResourceList;
            } catch {
                return;
            } finally {
                loading();
            }
        },
        redirectToMerchant() {
            if (this.data?.redirectUrl) {
                window.location.replace(this.data.redirectUrl);
            }
        },
        async getTextResources(countryCode: string | null | undefined, languageCode: LanguageCode, fetchFromTest: CallableFunction | null = null, httpsAgent: unknown = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = ["OtpExpiredTitle", "OtpExpiredDescription", "OtpReturnToShopButton"];

            return await getTranslations(sysnameList, countryCode, languageCode, fetchFromTest, httpsAgent);
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList?.textResourceItems, sysName, replace);
        },
        checkShowButton(otp: OtpResponse) {
            if (otp.data?.redirectExpiry == null) {
                return;
            }

            const redirectExpiry = otp.data.redirectExpiry;
            const expiryDate = new Date(redirectExpiry);
            const currentDate = new Date();
            this.showBackButton = currentDate < expiryDate;

            if (this.showBackButton) {
                setTimeout(() => {
                    this.checkShowButton(otp);
                }, 1000);
            }
        },
    },
});
